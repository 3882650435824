<template>
  <v-app>
    <v-main>
      <v-container class="title">
        <div class="d-flex flex-column align-center">
          <div class="text-h4 lable">
            <span class="red--text lable">I</span
            ><span class="lable">{{typedString}}</span>
          </div>
          <div class="text-subtitle-2">
            <span class="lable">Автомойки самообслуживания</span>
          </div>
        </div>
      </v-container>
      <v-container class="px-10">
        <div class="d-flex flex-column flex-md-row justify-space-between">
          <v-btn color="primary" class="mt-4" rounded @click="openmap(true)">
            <v-icon left> mdi-map-legend </v-icon>
            Как доехать
          </v-btn>
          <v-btn color="primary" class="mt-4" rounded @click="call">
            <v-icon left>mdi-phone-outline</v-icon> 8(800) 600-49-91</v-btn
          >
          <v-btn color="primary" class="mt-4" rounded @click="opentelegram">
            <svg
              width="24px"
              class="button__icon"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M17.086 20.3064C17.3677 20.5059 17.7308 20.5557 18.0546 20.4332C18.3783 20.3099 18.6163 20.0334 18.6881 19.6982C19.4484 16.1248 21.2929 7.08016 21.985 3.82961C22.0375 3.58462 21.95 3.33 21.7575 3.16638C21.565 3.00275 21.2981 2.95551 21.0601 3.04388C17.3914 4.40185 6.09273 8.64113 1.47459 10.35C1.18147 10.4585 0.990723 10.7402 1.00035 11.0491C1.01085 11.3588 1.21909 11.6274 1.51921 11.7176C3.59029 12.337 6.30885 13.1989 6.30885 13.1989C6.30885 13.1989 7.57932 17.0357 8.24168 18.9869C8.32481 19.2319 8.51643 19.4244 8.7693 19.4909C9.02129 19.5565 9.29078 19.4874 9.4789 19.3098C10.5429 18.3053 12.1878 16.7522 12.1878 16.7522C12.1878 16.7522 15.3133 19.0438 17.086 20.3064ZM7.45245 12.7142L8.92154 17.5598L9.24791 14.4912C9.24791 14.4912 14.9239 9.37173 18.1596 6.45368C18.2541 6.36793 18.2672 6.22443 18.1885 6.12381C18.1106 6.02319 17.9671 5.99956 17.8595 6.06781C14.1093 8.46263 7.45245 12.7142 7.45245 12.7142Z"
                fill="currentColor"
              ></path>
            </svg>
            Telegram
          </v-btn>
        </div>
        <div
          class="mt-5 d-flex flex-column flex-md-row align-start py-2"
          style="flex-wrap: wrap"
        >
          <div class="d-flex align-start mx-2 my-2">
            <v-icon
              size="15"
              color="grey"
              left
              style="flex-shrink: 0"
              class="mr-1"
              >mdi-map-marker-outline</v-icon
            >
            <span class="grey--text text-subtitle-2"
              >Россия, Республика Татарстан, Казань, Мамадышский тракт, 6</span
            >
          </div>
          <div class="d-flex align-start mx-2 my-2">
            <v-icon size="15" color="grey" left
              >mdi-clock-time-five-outline</v-icon
            >
            <span class="grey--text text-subtitle-2">Открыто</span>
          </div>
        </div>
      </v-container>
      <v-container

        class="d-flex flex-column flex-md-row justify-space-around"
        style="flex-wrap: wrap"
      >
        <v-card
          v-for="service in services"
          :key="service.name"
          class="mx-3 my-3"
          max-width="344"
          style="flex-wrap:wrap;!important"
        >
          <v-img :src="service.imgpath" height="200px"></v-img>
          <v-card-title>
            {{ service.title }}
          </v-card-title>
          <v-card-subtitle>
            {{ service.description }}
          </v-card-subtitle>
        </v-card>
        <v-dialog v-model="routeshow" width="600">
          <v-card>
            <v-card-title class="text-h5 primary lighten-2 outlined">
              Как доехать до автомойки
            </v-card-title>

            <v-card-text class="d-flex">
              <v-container class="d-flex align-center justify-center">
                <v-progress-circular
                  v-show="!loadedmap"
                  indeterminate
                  color="primary"
                />
                <iframe
                  v-show="loadedmap"
                  @load="loaded"
                  src="https://yandex.ru/map-widget/v1/?um=constructor%3A596ff6f1abadcf01f68a57d5077ebca1ff692bfb6bee47f12c7eb6e7f7ceea2e&amp;source=constructor"
                  width="500"
                  height="400"
                  frameborder="0"
                ></iframe>
              </v-container>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions
              class="primary outlined lighten-2"
              color="transparent"
            >
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="openmap(false)">
                Закрыть
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
      <v-container>
        <v-footer color="transparent" padless>
          <v-row class="mx-auto text-subtitle-2 text--secondary">
            <v-col class="text-subtitle"> ООО "Фортуна" </v-col>
            <v-col class="text-subtitle"> ИНН:7719875276 </v-col>
            <v-col class="text-subtitle"
              >Юр.адрес: 105264, г. Москва, ул. 7-я Парковая, дом 24 оф 506
            </v-col>
          </v-row>
        </v-footer>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      title:'-wash',
      showChars: 0,
      collapseOnScroll: true,
      loading: 0,
      loadedmap: false,
      routeshow: false,
      services: [
        {
          name: "pena",
          title: "Пена",
          cent: "45",
          imgpath: require("./assets/img/pena.png"),
          description: "Равномерное покрытие, профессиональная пена.",
        },
        {
          name: "voda",
          title: "Вода",
          cent: "35",
          imgpath: require("./assets/img/voda.png"),
          description: "Мягкая вода",
        },
        {
          name: "cvetnaja_pena",
          title: "Цветная пена",
          cent: "50",
          imgpath: require("./assets/img/cvetnaya-pena.jpg"),
          description:
            "Равномерное покрытие и лучшее визуальное восприятие, особенно на белом кузове.",
        },
        {
          name: "vozduh",
          title: "Воздух",
          cent: "20",
          imgpath: require("./assets/img/vozduh.png"),
          description:
            "Сжатый воздух. Для продувки от капель воды в труднодоступных местах",
        },
        {
          name: "vosk",
          title: "Воск",
          cent: "45",
          imgpath: require("./assets/img/vosk.jpg"),
          description:
            "Защита Авто. Жидким воском покрывается вся поверхность равномерно, оставляется на пару минут, а после остатки средства смываются водой. Процедура нанесения предполагает движение сверху вниз, то есть начать нужно с крыши, постепенно продвигаясь вниз.",
        },
        {
          name: "pilesos",
          title: "Пылесос",
          cent: "0",
          imgpath: require("./assets/img/pylesos.jpg"),
          description: "Услуга пылесос,- бесплатно!",
        },
      ],
    };
  },
  computed: {
    typedString() {
    return this.title.slice(0, this.showChars);
  },
  },
  methods: {
    loaded() {
      console.log("LOADED");
      this.loading = false;
      this.loadedmap = true;
    },
    openmap(s) {
      if (s) {
        if(!this.loadedmap){
          this.loading = true;
        }
        this.routeshow = true;
      } else {
        this.loading = false;
        this.routeshow = false;
      }
    },
    opentelegram(){
      window.location = 'https://t.me/S_Support_Iron'
    },
    call() {
      window.location = "tel:88006004991";
    },
  },
  created() {},
  mounted() {
    const intervalID = setInterval(() => {
    if (++this.showChars === this.title.length) {
      clearInterval(intervalID);
    }
  }, 250);
  },
  watch: {},
};
</script>

<style scope>
@font-face {
  font-family: "aSimplerDnm";
  src: url("./assets/aSimplerDnm.ttf");
}
html,
body,
main,
#app {
  overflow: auto !important;
}
.lable {
  font-family: "aSimplerDnm" !important;
}
</style>
